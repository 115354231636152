<template>
  <div class="container watermark-container">
    <van-nav-bar :title="titleValue" />
    <!-- 汇总数据区域 -->

    <div
      class="summary-wrap"
      style="
        display: flex;
        flex-wrap: wrap;
        border: 1px solid #ccc;
        border-radius: 4px;
        overflow: hidden;
      "
    >
      <div
        style="flex: 1 1 50%; box-sizing: border-box; border: 1px solid #ddd"
      >
        <van-cell title="当前在途订总单数" :value="summaryData.totalOrders" />
      </div>
      <div
        style="flex: 1 1 50%; box-sizing: border-box; border: 1px solid #ddd"
      >
        <van-cell
          title="当前应交配件总数"
          :value="summaryData.totalDelivered"
        />
      </div>
      <div
        style="flex: 1 1 50%; box-sizing: border-box; border: 1px solid #ddd"
      >
        <van-cell title="逾期率" :value="summaryData.overdueRate" />
      </div>
      <div
        style="flex: 1 1 50%; box-sizing: border-box; border: 1px solid #ddd"
      >
        <van-cell title="供应商等级" :value="summaryData.level" />
      </div>
      <div
        style="flex: 1 1 100%; box-sizing: border-box; border: 1px solid #ddd"
      >
        <van-cell title="最近的交期" :value="summaryData.latestDeliveryDate" />
      </div>
    </div>

    <!--查询表单-->
    <div class="search-div">
      <el-form label-width="50px" size="small">
        <el-row>
          <el-col :span="24">
            <el-form-item
              label="合同单号:"
              label-width="auto"
              style="white-space: nowrap"
              ><el-input
                style="width: 60%"
                v-model="searchObj.contractNumber"
                placeholder="如有多个以,隔开"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <!-- 第二行：下拉框 + 输入框 -->
          <el-col :span="10">
            <el-form-item label="排序顺序:" label-width="auto">
              <el-select
                v-model="searchObj.sortType"
                placeholder="排序顺序"
                style="width: 55%"
              >
                <el-option label="预到货时间" :value="1"></el-option>
                <el-option label="品名" :value="2"></el-option>
                <el-option label="下单时间" :value="3"></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="14">
            <el-form-item label="品名:" label-width="auto">
              <el-input
                style="width: 70%"
                v-model="searchObj.productName"
                placeholder="请输入品名"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <!-- 时间类型选择 -->
          <el-col :span="24">
            <el-form-item label="时间类型" label-width="auto">
              <el-select
                v-model="searchObj.timeType"
                placeholder="请选择时间类型"
                style="width: 60%"
              >
                <el-option label="预期到货时间" :value="1"></el-option>
                <el-option label="创建时间" :value="2"></el-option>
              </el-select>
            </el-form-item>

            <!-- 日期范围选择 -->
            <el-form-item label="时间范围" label-width="auto">
              <el-row>
                <!-- 开始日期 -->
                <el-col :span="9">
                  <el-date-picker
                    v-model="searchObj.startTime"
                    type="date"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    placeholder="请选择开始日期"
                    style="width: 100%"
                  />
                </el-col>

                <!-- 结束日期 -->
                <el-col :span="2" style="text-align: center; line-height: 36px">
                  ~
                </el-col>

                <el-col :span="9">
                  <el-date-picker
                    v-model="searchObj.endTime"
                    type="date"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    placeholder="请选择结束日期"
                    style="width: 100%"
                  />
                </el-col>
              </el-row>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row style="display: flex">
          <el-button
            type="primary"
            icon="el-icon-search"
            size="mini"
            :loading="loading"
            @click="onRefresh()"
            >搜索</el-button
          >
          <el-button icon="el-icon-refresh" size="mini" @click="resetData"
            >重置</el-button
          >
        </el-row>
      </el-form>
    </div>

    <van-tabs v-model="activeIndex" @click="tabSwitch">
      <van-tab v-for="(item, key) in tabList" :key="key" :title="item.title">
      </van-tab>
    </van-tabs>

    <div class="list-wrap">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
          :immediate-check="false"
        >
          <van-cell
            v-for="(item, index) in list"
            :key="index"
            @click="
              info(
                item.purchaseOrderType,
                item.purchaseOrderId,
                item.contractNumber,
                item.serialNumber,
                item.page
              )
            "
          >
            <template slot="default">
              <div class="item-wrap">
                <div class="item-header">
                  <img
                    src="https://static.dingtalk.com/media/lALOnahFD80CgM0CgA_640_640.png_450x10000q90.jpg"
                    alt=""
                  />
                  <h3
                    style="
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                    "
                  >
                    <span>{{ item.productName }}</span>
                    <!-- 添加序号 -->
                    <span style="color: #666; font-size: 15px">{{
                      index + 1
                    }}</span>
                  </h3>
                </div>

                <div class="item-block">
                  <div
                    style="
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                    "
                  >
                    <p>合同号：{{ item.contractNumber }}</p>
                    <!-- 剩余时间 -->
                    <span
                      :style="{
                        color: getRemainingDaysColor(item.remainingDays),
                        fontSize: '14px',
                        marginLeft: '10px',
                      }"
                    >
                      {{
                        item.remainingDays < 0
                          ? `已逾期 ${Math.abs(item.remainingDays)} 天`
                          : `剩余时间：${item.remainingDays} 天`
                      }}
                    </span>
                  </div>
                  <div
                    style="
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                    "
                  >
                    <p>应交数量：{{ item.quantityPurchased }}</p>
                    <span>
                      <span
                        v-if="item.reminderStatuses === 1"
                        style="
                          display: inline-block;
                          padding: 5px 10px;
                          border-radius: 12px;
                          background-color: #f39c12;
                          color: #fff;
                          font-size: 12px;
                          font-weight: 500;
                        "
                      >
                        已催单
                      </span>
                      <span
                        v-else-if="item.reminderStatuses === 4"
                        style="
                          display: inline-block;
                          padding: 5px 10px;
                          border-radius: 12px;
                          background-color: #27ae60;
                          color: #fff;
                          font-size: 12px;
                          font-weight: 500;
                        "
                      >
                        已确认
                      </span>
                      <span
                        v-else-if="item.reminderStatuses === -10"
                        style="
                          display: inline-block;
                          padding: 5px 10px;
                          border-radius: 12px;
                          background-color: #3498db;
                          color: #fff;
                          font-size: 12px;
                          font-weight: 500;
                        "
                      >
                        待确认
                      </span>
                      <!-- 下面这个span为后面添加 -->
                    </span>
                  </div>

                  <p>已交数量：{{ item.quantityDelivered }}</p>
                  <p>预到货日：{{ item.expectedArrivalTime }}</p>
                  <p v-if="item.supplierRemark">
                    反馈：{{ item.supplierRemark }}
                  </p>
                </div>
              </div>
            </template>
          </van-cell>
        </van-list>
      </van-pull-refresh>
    </div>

    <div>
      <!-- 弹出框 -->
      <el-dialog
        title="订单详情"
        :visible.sync="dialogVisible"
        width="80%"
        @close="handleClose"
      >
        <!-- 使用 iframe 来嵌套页面 -->
        <iframe
          v-if="iframeVisible"
          :src="iframeSrc"
          width="100%"
          height="500"
          frameborder="0"
        ></iframe>

        <!-- 弹出框底部的操作按钮 -->
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">关闭</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import api from '@/api/erpVendorIntransitOrders'

import addWatermark from '@/utils/watermark'
export default {
  name: 'process',

  data() {
    return {
      purchaseOrderType: '', // 详情所需参数
      purchaseOrderId: 0,
      contractNumber: '',
      serialNumber: '',
      oldPage: 1, // 详情所在页码
      dialogVisible: false, // 控制弹出框的显示状态
      iframeVisible: true, // 控制 iframe 的显示和销毁

      titleValue: '供应商在途订单列表', // 标题

      list: [],
      loading: false,
      loadingData: false,
      finished: false,
      refreshing: false,

      // 汇总数据
      summaryData: {
        totalOrders: '', // 总订单数
        totalDelivered: '', // 总产品数
        latestDeliveryDate: '', // 最近一次交期
        level: '', // 等级
        overdueRate: '', //逾期率
        vendorName: '', // 供应商名称
        userName: '', // 用户名称
      },

      pageNo: 1,
      pageSize: 10,
      pages: 1,
      total: 0, // 数据总数
      searchObj: {
        sortType: 1,
        timeType: 1,
      },
      activeIndex: 0,
      tabList: [{ title: '全部' }, { title: '今日新增' }, { title: '已到期' }],
    }
  },

  computed: {
    iframeSrc() {
      const timestamp = new Date().getTime() // 获取当前时间戳
      // 使用 window.location.origin 生成完整路径
      const page = this.oldPage // 计算当前页码

      return (
        window.location.origin +
        `/#/supplierShow/${this.purchaseOrderType}/${this.purchaseOrderId}/${this.contractNumber}/${this.serialNumber}?t=${timestamp}&page=${page}`
      )
    },
  },

  created() {
    this.activeIndex = parseInt(this.$route.params.activeIndex)

    this.onLoad()
  },

  mounted() {
    window.addEventListener('message', this.handleMessage)

    // 在途订单 总结
    this.orderSummary()
  },
  beforeDestroy() {
    window.removeEventListener('message', this.handleMessage)
  },

  methods: {
    // 供应商汇总数据
    orderSummary() {
      api.orderSummary().then((response) => {
        this.summaryData = response.data
        this.titleValue =
          '供应商 ' + this.summaryData.vendorName + '在途订单列表'

        // 调用水印功能
        addWatermark({
          containerSelector: '.watermark-container', // 添加水印的目标容器
          text: this.summaryData.vendorName + ' ' + this.summaryData.userName, // 自定义水印文本
          font: '16px Arial',
          color: 'rgba(0, 0, 0, 0.15)',
          width: 200,
          height: 150,
          angle: -30,
        })
      })
    },

    // 监听详情页的参数
    handleMessage(event) {
      console.log('监听参数:' + event)
      if (event.data.action === 'onLoad') {
        const page = event.data.page // 获取页码
        this.refreshList(page)
      }
    },
    // 刷新某一页的数据
    refreshList(page) {
      if (this.activeIndex === 0) {
        this.fetchPageInSupplierAll(page)
      }
      if (this.activeIndex === 1) {
        this.fetchPageInSupplierVerySoon(page)
      }
      if (this.activeIndex === 2) {
        this.fetchPageInSupplierExpired(page)
      }

      // 删除从下标 i 开始的所有数据
      this.list.splice(page * this.pageSize)
    },

    tabSwitch() {
      //tab切换，重新初始化数据
      this.list = []
      this.pageNo = 1
      this.finished = false

      //tabs切换时，如果之前的tab已经滚动到底部（list加载到底部），直接点击其他的tab，将再触发一次onload事件。
      //可能调用2次onLoad()方法，延迟执行，通过时间差解决问题
      setTimeout(() => {
        if (!this.finished) {
          this.onLoad()
        }
      }, 500)
    },

    onLoad() {
      // 如果正在加载，直接返回
      if (this.loadingData) return

      this.loadingData = true // 标记为加载状态

      // // 根据选中的标签，选择不同的请求方法
      // switch (this.activeIndex) {
      //   case 0:
      //     return this.pageInSupplierAll() // 异步调用
      //   case 1:
      //     return this.pageInSupplierVerySoon()
      //   case 2:
      //     return this.pageInSupplierExpired()
      //   default:
      //     return Promise.resolve()
      // }
      let promise
      switch (this.activeIndex) {
        case 0:
          promise = this.pageInSupplierAll()
          break
        case 1:
          promise = this.pageInSupplierVerySoon()
          break
        case 2:
          promise = this.pageInSupplierExpired()
          break
        default:
          promise = Promise.resolve()
      }

      // 确保 modifyTitle() 运行
      promise.then(() => {
        this.modifyTitle()
      })
    },

    // 修改标题
    modifyTitle() {
      this.tabList = [
        { title: '全部' },
        { title: '今日新增' },
        { title: '已到期' },
      ]
      let index = this.activeIndex
      let oldTitle = this.tabList[index].title
      this.tabList[index].title = oldTitle + '(' + this.total + ')'
    },

    resetData() {
      this.searchObj = {
        sortType: 1,
        timeType: 1,
      }

      this.onRefresh()
    },

    // 刷新
    onRefresh() {
      if (this.loadingData) return

      // 清空列表数据
      this.finished = false
      this.list = []

      this.pageNo = 1
      // 重新加载数据
      this.onLoad().finally(() => {
        this.loadingData = false // 请求完成后清除加载状态
      })
    },

    // 根据剩余天数返回对应的颜色
    getRemainingDaysColor(remainingDays) {
      if (remainingDays < 0) {
        return 'red' // 小于0时，红色
      } else if (remainingDays <= 3) {
        return 'orange' // 小于等于3且大于等于0时，橙色
      } else if (remainingDays <= 7) {
        return '#ffc639' // 小于等于7时，暖橙色
      } else {
        return 'green' // 大于7时，绿色
      }
    },

    pageInSupplierAll() {
      return api
        .pageInSupplierAll(this.pageNo, this.pageSize, this.searchObj)
        .then((response) => {
          console.log(response.data)
          if (this.refreshing) {
            this.list = []
            this.refreshing = false
          }
          for (let i = 0; i < response.data.records.length; i++) {
            let item = response.data.records[i]
            item.page = this.pageNo // 给每个订单保存当前页码
            this.list.push(item)
          }
          this.pages = response.data.pages
          if (this.pageNo == 1) {
            this.total = response.data.total
          }

          this.loading = false
          if (this.pageNo >= this.pages) {
            this.finished = true
          }
          this.loadingData = false //清除加载状态
          this.pageNo++
        })
        .catch(() => {
          this.loadingData = false // 请求失败后清除加载状态
        })
    },
    fetchPageInSupplierAll(page) {
      api
        .pageInSupplierAll(page, this.pageSize, this.searchObj)
        .then((response) => {
          // 计算当前页的起始索引
          const startIndex = (page - 1) * this.pageSize

          for (let i = 0; i < response.data.records.length; i++) {
            let item = response.data.records[i]
            item.page = page // 给每个订单保存当前页码
            // 如果当前索引存在于 `list` 中，则替换，否则插入
            if (this.list[startIndex + i]) {
              this.list[startIndex + i] = item // 替换已有数据
            } else {
              this.list.push(item) // 插入新数据（超出当前长度）
            }
          }
          this.loadingData = false // 请求失败后清除加载状态
        })
    },

    // 查看今日新增列表
    pageInSupplierVerySoon() {
      return api
        .pageInSupplierVerySoon(this.pageNo, this.pageSize, this.searchObj)
        .then((response) => {
          console.log(response.data)
          if (this.refreshing) {
            this.list = []
            this.refreshing = false
          }
          for (let i = 0; i < response.data.records.length; i++) {
            let item = response.data.records[i]
            item.page = this.pageNo // 给每个订单保存当前页码
            // item.formValues = JSON.parse(item.formValues)
            this.list.push(item)
          }
          this.pages = response.data.pages
          if (this.pageNo == 1) {
            this.total = response.data.total
          }

          this.loading = false
          if (this.pageNo >= this.pages) {
            this.finished = true
          }

          this.pageNo++
          this.loadingData = false // 清除加载状态
        })
        .catch(() => {
          this.loadingData = false // 请求失败后清除加载状态
        })
    },
    fetchPageInSupplierVerySoon(page) {
      api
        .pageInSupplierVerySoon(page, this.pageSize, this.searchObj)
        .then((response) => {
          // 计算当前页的起始索引
          const startIndex = (page - 1) * this.pageSize

          for (let i = 0; i < response.data.records.length; i++) {
            let item = response.data.records[i]
            item.page = page // 给每个订单保存当前页码
            // 如果当前索引存在于 `list` 中，则替换，否则插入
            if (this.list[startIndex + i]) {
              this.list[startIndex + i] = item // 替换已有数据
            } else {
              this.list.push(item) // 插入新数据（超出当前长度）
            }
          }
          this.loadingData = false // 请求失败后清除加载状态
        })
    },

    pageInSupplierExpired() {
      return api
        .pageInSupplierExpired(this.pageNo, this.pageSize, this.searchObj)
        .then((response) => {
          console.log(response.data)
          if (this.refreshing) {
            this.list = []
            this.refreshing = false
          }
          for (let i = 0; i < response.data.records.length; i++) {
            let item = response.data.records[i]
            item.page = this.pageNo // 给每个订单保存当前页码
            this.list.push(item)
          }
          this.pages = response.data.pages
          if (this.pageNo == 1) {
            this.total = response.data.total
          }

          this.loading = false
          if (this.pageNo >= this.pages) {
            this.finished = true
          }

          this.pageNo++
          this.loadingData = false // 清除加载状态
        })
        .catch(() => {
          this.loadingData = false // 请求失败后清除加载状态
        })
    },

    fetchPageInSupplierExpired(page) {
      api
        .pageInSupplierExpired(page, this.pageSize, this.searchObj)
        .then((response) => {
          // 计算当前页的起始索引
          const startIndex = (page - 1) * this.pageSize

          for (let i = 0; i < response.data.records.length; i++) {
            let item = response.data.records[i]
            item.page = page // 给每个订单保存当前页码
            // 如果当前索引存在于 `list` 中，则替换，否则插入
            if (this.list[startIndex + i]) {
              this.list[startIndex + i] = item // 替换已有数据
            } else {
              this.list.push(item) // 插入新数据（超出当前长度）
            }
          }
          this.loadingData = false // 请求失败后清除加载状态
        })
    },

    info(
      purchaseOrderType,
      purchaseOrderId,
      contractNumber,
      serialNumber,
      oldPage
    ) {
      // 查询所需参数
      this.purchaseOrderType = purchaseOrderType
      this.purchaseOrderId = purchaseOrderId
      this.contractNumber = contractNumber
      this.serialNumber = serialNumber

      // 记录原本页码
      this.oldPage = oldPage
      this.dialogVisible = true

      this.iframeVisible = false // 销毁 iframe
      this.$nextTick(() => {
        this.iframeVisible = true // 重新显示 iframe
      })
    },

    // 关闭弹窗
    handleClose() {
      this.dialogVisible = false
      this.purchaseOrderType = ''
      this.purchaseOrderId = 0
      this.contractNumber = ''
      this.serialNumber = ''
      this.oldPage = 1
    },
  },
}
</script>

<style lang="scss" scoped>
/deep/ .van-nav-bar {
  background: #1d1e20;
}
/deep/ .van-nav-bar__title {
  color: #fff;
}
.container {
  padding-bottom: 50px;
  .list-wrap {
    margin-top: 4px;
    border-top: 1px solid #ebedf0;
  }
  .item-wrap {
    font-size: 12px;
    color: #a7a8a9;
    .item-header {
      display: flex;
      align-items: center;
      img {
        width: 20px;
        height: 20px;
        border-radius: 4px;
        margin-right: 4px;
      }
      h3 {
        flex: 1;
        font-size: 15px;
        color: #000;
        padding: 0;
        margin: 0;
      }
    }

    .item-block {
      padding: 4px 0;
      border: 1px solid #ddd; /* 添加边框，提升视觉分隔 */
      font-size: 14px;
      p {
        padding: 0;
        margin: 5px 0;
        color: #000;
        line-height: 20px;
      }
    }

    .item-status {
      .pass {
        color: #4cb971;
      }
      .refused {
        color: #eb8473;
      }
    }

    .summary-wrap {
      margin-bottom: 10px;
      background-color: #f7f8fa;
      border-radius: 8px;
      overflow: hidden;
    }
  }
}
</style>
